import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 72",
  week: "Semana 11",
  day: "24",
  month: "May",
  monthNumber: "05",
  date: "2020-05-24",
  path: "/cronologia/semana-11#dia-24-may/",
};

const Day72 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="se"
          data="casosConfirmados"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 455 nuevos positivos y 52 personas fallecidas. Permanecen
          hospitalizadas 124.521 personas, 95 menos que el día anterior. El
          número de casos ingresados en la UCI asciende a 11.454, lo que supone
          un aumento de 9 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % supera el 53,7 %.
        </ModText>
        <ModText>
          El Ministerio de Ciencia e Innovación ha publicado una Guia para para
          tiendas, centros educativos y oficinas, con consejos a seguir para el
          proceso de apertura y la transición durante est fase de la epidemia.
        </ModText>

        <ModBuscandoDatos num="random" />
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.ciencia.gob.es/stfls/MICINN/Ministerio/FICHEROS/Ocho_claves_para_abrir_mas_tranquilos_200522.pdf"
            name="Ocho claves para abrir más tranquilos"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day72;
